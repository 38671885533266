import React, { useState, useEffect, useCallback } from 'react';
import { fetchDevices, handleApiError } from '../services/api';
import { Device, PaginatedResponse, PaginationParams } from '../models/adminInterfaces';
import Pagination from './Pagination';

const Devices: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchDevicesData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params: PaginationParams = { page };
      const response: PaginatedResponse<Device> = await fetchDevices(params);
      setDevices(response.data);
      setTotalPages(response.total_pages);
    } catch (err) {
      setError(handleApiError(err));
    }
    setLoading(false);
  }, [page]);

  useEffect(() => {
    fetchDevicesData();
  }, [fetchDevicesData]);

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Devices</h2>

      {loading && <p className="text-center">Loading...</p>}
      {error && <p className="text-center text-red-600">{error}</p>}

      {!loading && !error && (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Device ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Credits
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {devices.map((device) => (
                  <tr key={device.device_id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{device.device_id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{device.credits}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(device.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

export default Devices;