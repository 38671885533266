import React, { useState, useRef, useEffect, useCallback } from 'react';
import html2canvas from 'html2canvas';

interface Theme {
  gradientStart: string;
  gradientEnd: string;
  accentColor: string;
}

const themes: Record<string, Theme> = {
  ocean: {
    gradientStart: '#4CB0C6',
    gradientEnd: '#2E4857',
    accentColor: '#30596E'
  },
  sunset: {
    gradientStart: '#FF5F6D',
    gradientEnd: '#FFC371',
    accentColor: '#FF3A4C'
  },
  forest: {
    gradientStart: '#5A8F69',
    gradientEnd: '#2F554D',
    accentColor: '#3C7C65'
  },
  midnight: {
    gradientStart: '#1F1C2C',
    gradientEnd: '#928DAB',
    accentColor: '#524A67'
  }
};

const ImageGenerator: React.FC = () => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [image, setImage] = useState<string | null>(null);
  const [theme, setTheme] = useState('ocean');
  const [resolution, setResolution] = useState('2880x1800');

  const previewRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const subtitleRef = useRef<HTMLParagraphElement>(null);

  const truncateText = useCallback((element: HTMLElement, text: string, placeholder: string) => {
    const maxWidth = element.offsetWidth;
    let content = text.trim() || placeholder;
    element.textContent = content;

    if (element.scrollWidth > maxWidth) {
      let low = 0;
      let high = content.length;
      let mid;

      while (low <= high) {
        mid = Math.floor((low + high) / 2);
        element.textContent = content.slice(0, mid) + '...';
        
        if (element.scrollWidth <= maxWidth) {
          low = mid + 1;
        } else {
          high = mid - 1;
        }
      }

      element.textContent = content.slice(0, high) + '...';
    }
  }, []);

  const updatePreview = useCallback(() => {
    if (titleRef.current && subtitleRef.current) {
      truncateText(titleRef.current, title, 'Your App Title');
      truncateText(subtitleRef.current, subtitle, 'Your App Subtitle');
    }
  }, [title, subtitle, truncateText]);

  useEffect(() => {
    updatePreview();
  }, [updatePreview, theme]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setImage(e.target?.result as string);
      reader.readAsDataURL(file);
    }
  };

  const generateImage = () => {
    if (previewRef.current) {
      const [width, height] = resolution.split('x').map(Number);
      const scale = window.devicePixelRatio || 1;

      html2canvas(previewRef.current, {
        scale: scale,
        width: previewRef.current.offsetWidth,
        height: previewRef.current.offsetHeight,
        useCORS: true
      }).then(canvas => {
        const scaledCanvas = document.createElement('canvas');
        scaledCanvas.width = width;
        scaledCanvas.height = height;
        const ctx = scaledCanvas.getContext('2d');

        if (ctx) {
          const scaleFactor = width / canvas.width;
          const scaledHeight = canvas.height * scaleFactor;

          const selectedTheme = themes[theme];
          const gradient = ctx.createLinearGradient(0, 0, width, height);
          gradient.addColorStop(0, selectedTheme.gradientStart);
          gradient.addColorStop(1, selectedTheme.gradientEnd);
          ctx.fillStyle = gradient;
          ctx.fillRect(0, 0, width, height);

          ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, width, scaledHeight);

          const link = document.createElement('a');
          link.download = `app-review-image-${width}x${height}.png`;
          link.href = scaledCanvas.toDataURL('image/png');
          link.click();
        }
      });
    }
  };

  const PlaceholderSVG = () => (
    <svg
      className="w-full text-gray-300"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4h16v16H4z" />
      <path d="M4 4h16v16H4z" fill="none" stroke="currentColor" strokeWidth="2" />
      <path d="M6 6h12v12H6z" fill="white" stroke="currentColor" strokeWidth="1" />
      <path d="M8 16l3-3 2 2 4-4 3 3" stroke="currentColor" strokeWidth="1" fill="none" />
      <circle cx="9" cy="9" r="2" />
    </svg>
  );

  const getAspectRatio = () => {
    const [width, height] = resolution.split('x').map(Number);
    return (height / width) * 100;
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="lg:w-1/3 p-6 space-y-4">
        <h2 className="text-2xl font-bold">macOS App Review Image Creator</h2>
        <input
          type="text"
          placeholder="Enter app title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded"
        />
        <input
          type="text"
          placeholder="Enter app subtitle"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
          className="w-full p-2 border rounded"
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="w-full p-2 border rounded"
        />
        <select
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
          className="w-full p-2 border rounded"
        >
          {Object.keys(themes).map((t) => (
            <option key={t} value={t}>{t} Theme</option>
          ))}
        </select>
        <select
          value={resolution}
          onChange={(e) => setResolution(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="2880x1800">2880x1800</option>
          <option value="2560x1600">2560x1600</option>
          <option value="1440x900">1440x900</option>
          <option value="1280x800">1280x800</option>
        </select>
        <button
          onClick={generateImage}
          className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Generate Image
        </button>
      </div>
      <div className="lg:w-2/3 p-6">
        <div
          ref={previewRef}
          className="w-full shadow-lg overflow-hidden relative"
          style={{
            paddingBottom: `${getAspectRatio()}%`,
            background: `linear-gradient(to bottom right, ${themes[theme].gradientStart}, ${themes[theme].gradientEnd})`,
          }}
        >
          <div className="absolute inset-0 p-8 flex flex-col">
            <div className="text-center mb-4">
              <h1 ref={titleRef} className="text-3xl font-bold text-white mb-2">
                {title || 'Your App Title'}
              </h1>
              <p ref={subtitleRef} className="text-xl text-white opacity-90">
                {subtitle || 'Your App Subtitle'}
              </p>
            </div>
            <div className="flex-grow flex items-center justify-center">
              <div className="bg-gray-800 rounded-lg p-4 w-full h-4/5 max-w-3xl mx-auto">
                <div className="w-full overflow-hidden relative flex items-center justify-center">
                  {image ? (
                    <img src={image} alt="App Screenshot" className="w-full" />
                  ) : (
                    <PlaceholderSVG />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGenerator;