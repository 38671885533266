import axios, { AxiosError, AxiosInstance } from 'axios';
import { 
  Device, 
  Purchase, 
  CreditUsage, 
  PaginatedResponse,
  PaginationParams
} from '../models/adminInterfaces';

const ADMIN_API_BASE_URL = process.env.REACT_APP_ADMIN_API_BASE_URL || 'http://localhost:5000';

const createAxiosInstance = (): AxiosInstance => {
  return axios.create({
    baseURL: ADMIN_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

let axiosInstance = createAxiosInstance();

export const setAuthToken = (token: string) => {
  axiosInstance = createAxiosInstance();
  axiosInstance.defaults.headers.common['Authorization'] = token;
};

export const clearAuthToken = () => {
  axiosInstance = createAxiosInstance();
};

export const login = async (username: string, password: string): Promise<string> => {
  console.log('Debug: Attempting login');
  try {
    const response = await axiosInstance.post('/admin/login', { username, password });
    console.log('Debug: Login response:', response.data);
    if (response.status === 200 && response.data.token) {
      setAuthToken(response.data.token);
      return response.data.token;
    } else {
      throw new Error('Login failed');
    }
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = async (): Promise<void> => {
  console.log('Debug: Logging out');
  clearAuthToken();
};

export const fetchDevices = async (params: PaginationParams): Promise<PaginatedResponse<Device>> => {
  console.log('Debug: Fetching devices with params:', params);
  try {
    const response = await axiosInstance.get('/admin/devices', { params });
    console.log('Debug: Devices response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching devices:', error);
    throw error;
  }
};

export const fetchPurchases = async (params: PaginationParams): Promise<PaginatedResponse<Purchase>> => {
  console.log('Debug: Fetching purchases with params:', params);
  try {
    const response = await axiosInstance.get('/admin/purchases', { params });
    console.log('Debug: Purchases response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching purchases:', error);
    throw error;
  }
};

export const fetchCreditUsages = async (params: PaginationParams): Promise<PaginatedResponse<CreditUsage>> => {
  console.log('Debug: Fetching credit usages with params:', params);
  try {
    const response = await axiosInstance.get('/admin/credit_usages', { params });
    console.log('Debug: Credit usages response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching credit usages:', error);
    throw error;
  }
};

export const handleApiError = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      if (axiosError.response.status === 401) {
        // Token expired or invalid, clear the token and redirect to login
        clearAuthToken();
        // You might want to trigger a redirection to the login page here
        return "Session expired. Please log in again.";
      }
      console.error("Error data:", axiosError.response.data);
      console.error("Error status:", axiosError.response.status);
      return `Error: ${(axiosError.response.data as any).error || 'An unexpected error occurred'}`;
    } else if (axiosError.request) {
      console.error("Error request:", axiosError.request);
      return "Error: No response received from server";
    } else {
      console.error("Error message:", axiosError.message);
      return `Error: ${axiosError.message}`;
    }
  } else {
    console.error("Non-Axios error:", error);
    return "An unexpected error occurred";
  }
};