import React, { useState, useEffect, useCallback } from 'react';
import { fetchPurchases, handleApiError } from '../services/api';
import { Purchase, PaginatedResponse, PaginationParams } from '../models/adminInterfaces';
import Pagination from './Pagination';

const Purchases: React.FC = () => {
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPurchasesData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params: PaginationParams = { page };
      const response: PaginatedResponse<Purchase> = await fetchPurchases(params);
      setPurchases(response.data);
      setTotalPages(response.total_pages);
    } catch (err) {
      setError(handleApiError(err));
    }
    setLoading(false);
  }, [page]);

  useEffect(() => {
    fetchPurchasesData();
  }, [fetchPurchasesData]);

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Purchases</h2>

      {loading && <p className="text-center">Loading...</p>}
      {error && <p className="text-center text-red-600">{error}</p>}

      {!loading && !error && (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Device ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Product ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {purchases.map((purchase) => (
                  <tr key={`${purchase.device_id}-${purchase.created_at}`}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{purchase.device_id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{purchase.product_id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${purchase.amount.toFixed(2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(purchase.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

export default Purchases;