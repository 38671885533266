import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { logout } from '../services/api';

const Topbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, logout: authLogout } = useAuth();

  const isActive = (path: string) => {
    return location.pathname === path ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white';
  };

  const handleLogout = async () => {
    try {
      await logout();
      authLogout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
      // Optionally, you can show an error message to the user here
    }
  };

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <h1 className="text-white font-bold text-xl">Dashboard</h1>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-center space-x-4">
                <Link to="/" className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/')}`}>
                  Devices
                </Link>
                <Link to="/purchases" className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/purchases')}`}>
                  Purchases
                </Link>
                <Link to="/credit-usages" className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/credit-usage')}`}>
                  Credit Usages
                </Link>
                <Link to="/tools" className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/tools')}`}>
                  Tools
                </Link>
              </div>
            </div>
          </div>
          {isAuthenticated && (
            <div>
              <button
                onClick={handleLogout}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Topbar;