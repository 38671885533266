import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Login from './components/Login';
import Devices from './components/Devices';
import Purchases from './components/Purchases';
import CreditUsages from './components/CreditUsages';
import ImageGenerator from './components/ImageGenerator';
import Topbar from './components/Topbar';

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const AppRoutes: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Topbar />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/devices" element={<PrivateRoute element={<Devices />} />} />
          <Route path="/purchases" element={<PrivateRoute element={<Purchases />} />} />
          <Route path="/credit-usages" element={<PrivateRoute element={<CreditUsages />} />} />
          <Route path="/tools" element={<PrivateRoute element={<ImageGenerator />} />} />
          <Route path="*" element={<Navigate to="/devices" replace />} />
        </Routes>
      </main>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
};

export default App;